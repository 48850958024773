import Image from 'next/image';
import Link from 'next/link';
import entremalhasLogo from '#/assets/images/entremalhas-logo.svg';
import { AspectRatio } from './ui/aspect-ratio';

export function Logo() {
  return (
    <Link
      href="/"
      className="w-44 min-w-[100px] md:w-52"
    >
      <AspectRatio ratio={7}>
        <Image
          src={entremalhasLogo}
          alt="Entremalhas Logo"
          fill
          priority
        />
      </AspectRatio>
    </Link>
  );
}
