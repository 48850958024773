import type { ComponentProps } from 'react';
import type { OfficeHourItem } from '#/common/config/footerNav';
import { cn } from '#/utils/cn';

type FooterOfficeHoursProps = Pick<ComponentProps<'div'>, 'className'> & {
  items: OfficeHourItem[];
};

export const FooterOfficeHours = ({ className, items }: FooterOfficeHoursProps) => {
  return (
    <div className={cn('max-w-[400px] space-y-1', className)}>
      <span className="cursor-default font-semibold text-sm uppercase">Atendimento</span>
      <ul className="space-y-0.5 md:space-y-1 xl:space-y-1.5">
        {items.map(({ fromDay, fromHour, toHour, months, toDay }) => (
          <li
            key={`${fromDay}${Math.random()}`}
            className="flex items-center justify-between text-sm text-white/80"
          >
            <div className="flex items-center gap-1">
              {!toDay ? (
                <p>{fromDay}s</p>
              ) : (
                <p>
                  {fromDay} a {toDay}
                </p>
              )}
              {months && (
                <p>
                  |{' '}
                  {months.map((month, index) => {
                    if (index < months.length - 1) {
                      return (
                        <span
                          key={month}
                        >{`${month}${index === months.length - 2 ? ' e ' : ', '}`}</span>
                      );
                    }
                    return <span key={month}>{month}</span>;
                  })}
                </p>
              )}
            </div>
            <div className="flex items-center gap-1">
              <p>
                {fromHour} - {toHour}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
