import { isValidCEP } from '@brazilian-utils/brazilian-utils';
import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useCustomer } from '#/common/hooks/useCustomer';
import { southAndSouthEastStates } from '#/common/models/FreightStates';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { TextInput } from '#/core/inputs/text-input';
import { INPUT_MASKS } from '#/core/inputs/util-masks';
import { Checkbox } from '#/ui/checkbox';
import { trpc } from '#/utils/trpc';
import { Icon } from '../Icon';
import { useToast } from '../ui/use-toast';

interface Props {
  isPending: boolean;
}

export function CartFreight({ isPending }: Props) {
  const { toast } = useToast();

  const { inStorePickup, toggleInStorePickup, setFreight, freight } = useCartStore(
    (state) => ({
      inStorePickup: state.inStorePickup,
      toggleInStorePickup: state.toggleInStorePickup,
      setFreight: state.setFreight,
      freight: state.freight,
    }),
  );

  const { customerPostcode } = useCustomer((state) => ({
    customerPostcode: state.customer.shipping.postcode,
  }));

  const statePostcode =
    freight.shippingPostcode && freight.shippingPostcode !== 'off_topic'
      ? String(freight.shippingPostcode)
      : customerPostcode;

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: { postcode: statePostcode },
  });

  const postcode =
    useWatch({
      control: formMethods.control,
      name: 'postcode',
    }) || statePostcode;

  const {
    data: address,
    isLoading: isLoadingAddress,
    error: addressError,
  } = trpc.addressRouter.getAddress.useQuery(
    { postcode },
    {
      enabled: isValidCEP(postcode) && !inStorePickup,
    },
  );

  const handleToggleInStorePickup = () => {
    setFreight({
      freightPrice: null,
      shippingPostcode: null,
    });
    toggleInStorePickup();
  };

  useEffect(() => {
    if (address && !inStorePickup) {
      const { state_code } = address;
      const isSouthOrSouthEast = southAndSouthEastStates.includes(state_code);

      setFreight({
        freightPrice: isSouthOrSouthEast ? null : 'off_topic',
        shippingPostcode: postcode,
      });
    } else if (addressError) {
      toast({
        title:
          'Tivemos problemas ao buscar pelo frete, por favor tente novamente mais tarde.',
        variant: 'destructive',
      });
      setFreight({
        freightPrice: null,
        shippingPostcode: null,
      });
    }
  }, [address, inStorePickup, postcode, addressError, setFreight, toast]);

  return (
    <div className="flex w-full flex-col gap-2 pt-4 sm:gap-3">
      <div className="flex items-center gap-2.5 sm:gap-3">
        <Checkbox
          id="inStorePickup"
          checked={inStorePickup}
          className="border-black/90 data-[state=checked]:bg-black/90"
          onClick={handleToggleInStorePickup}
          disabled={isPending}
        />
        <p className="text-[13px] text-gray-700 sm:text-sm dark:text-gray-300">
          Desejo retirar na loja Entremalhas
        </p>
      </div>

      {!inStorePickup && (
        <div className="space-y-0.5">
          <div className="relative">
            <FormProvider {...formMethods}>
              <TextInput
                label="CEP"
                source="postcode"
                iconProps={{ name: 'signpost' }}
                inputProps={{ maskType: INPUT_MASKS.CEP_MASK }}
                isDisabled={isPending}
              />
            </FormProvider>
            {(isPending || isLoadingAddress) && (
              <Icon
                name="loader"
                className="absolute right-3 bottom-2.5 animate-spin"
                size={20}
              />
            )}
          </div>
          {!isValidCEP(postcode) && postcode !== '' && (
            <p className="text-red-600 text-xs sm:text-sm">CEP inválido!</p>
          )}
          {addressError && (
            <p className="text-red-600 text-xs sm:text-sm">
              Tivemos problemas ao buscar pelo endereço.
            </p>
          )}
        </div>
      )}
    </div>
  );
}
