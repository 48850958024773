import { z } from 'zod';
import { PostcodeSchema } from '#/common/schemas/Postcode.schema';

export const AddressSchema = z.object({
  postcode: PostcodeSchema,
  address: z.string().min(1, 'Campo obrigatório'),
  complement: z.string().optional(),
  city: z.string().min(1, 'Campo obrigatório'),
  state_code: z.string().min(2, 'Campo obrigatório'),
});

export type Address = z.infer<typeof AddressSchema>;
