import { Button } from '#/ui/button';
import { Input } from '#/ui/input';
import { Icon } from '../../Icon';
import { useSearchInput } from './useSearchInput';

export function SearchInput() {
  const { handleChange, products, handleSubmit } = useSearchInput();
  return (
    <form
      className="relative w-full"
      onSubmit={handleSubmit}
    >
      <Input
        list="products"
        id="products-choice"
        name="products-choice"
        placeholder="Digite o nome do produto"
        onChange={handleChange}
        className="h-[48px] pr-14 text-[13px] md:text-sm"
      />

      <datalist id="products">
        {products.map((product) => (
          <option
            key={product}
            value={product}
            className="w-full"
          >
            {product}
          </option>
        ))}
      </datalist>
      <Button
        size="icon"
        asChild
      >
        <button
          type="submit"
          aria-label="Botão para realizar a busca"
          title="Pesquisar resultados"
          className="absolute top-2 right-2 h-8 w-9 cursor-pointer p-0"
        >
          <Icon
            name="search"
            size={18}
            strokeWidth={2.2}
          />
        </button>
      </Button>
    </form>
  );
}
