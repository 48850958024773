import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useCustomer } from '#/common/hooks/useCustomer';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { TextInput } from '#/core/inputs/text-input';
import { Checkbox } from '#/ui/checkbox';
import { trpc } from '#/utils/trpc';
import { Icon } from '../Icon';
import { Button } from '../ui/button';

export function CartCoupon() {
  const email = useCustomer(({ customer }) => customer.email);
  const {
    coupon,
    setCoupon,
    setTotalAmountCart,
    products,
    setDiscount,
    totalAmountCart,
  } = useCartStore((state) => ({
    coupon: state.coupon,
    setCoupon: state.setCoupon,
    setTotalAmountCart: state.setTotalAmountCart,
    setDiscount: state.setDiscount,
    totalAmountCart: state.totalAmountCart,
    products: state.productsOnCart.map(([product_id, value]) => ({
      product_id,
      quantity: value.quantity,
      actual_price_number: value.actual_price_number,
      categories_ids: value.categories_ids,
      sale_item: false,
    })),
  }));

  const { mutateAsync, isPending } = trpc.couponRouter.applyCoupon.useMutation();

  const formMethods = useForm<{ coupon_code: string }>({
    mode: 'onBlur',
    defaultValues: { coupon_code: coupon.code },
  });
  const couponCode = useWatch({
    control: formMethods.control,
    name: 'coupon_code',
  });

  const handleDebouncedOnChangeCouponInput = useDebounceCallback(async () => {
    setDiscount(0);
    setCoupon({
      loading: true,
      isApplied: false,
      valid: null,
      code: couponCode,
    });

    try {
      if (couponCode.trim() !== '') {
        const response = await mutateAsync({
          payload: { code: couponCode, email, products },
        });
        setCoupon({
          valid: response.is_valid_coupon,
          discountPercentage: response.discount_percentage,
          check: true,
          isApplied: true,
          code: couponCode,
        });

        setDiscount(totalAmountCart - response.total_amount);
        setTotalAmountCart(response.total_amount);
      } else {
        setCoupon({ check: true });
      }
    } catch (error) {
      setCoupon({
        valid: false,
        discountPercentage: null,
      });
    }

    setCoupon({ loading: false });
  }, 500);

  const handleCouponCheck = () =>
    setCoupon({
      check: !coupon.check,
      valid: null,
      discountPercentage: null,
    });

  const couponSuccess = !isPending && Boolean(coupon.valid);
  const couponFailure = !isPending && coupon.valid === false;
  return (
    <FormProvider {...formMethods}>
      <div className="flex w-full flex-col gap-2 pt-4 sm:gap-3">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2.5 sm:gap-3">
            <Checkbox
              className="border-black/90 data-[state=checked]:bg-black/90"
              checked={coupon.check}
              onClick={handleCouponCheck}
            />
            <p className="text-[13px] text-gray-700 sm:text-sm dark:text-gray-300">
              Tenho cupom de desconto
            </p>
          </div>
          {coupon.check && coupon.discountPercentage && !coupon.loading && (
            <p className="font-semibold text-green-800 text-sm">
              {coupon.discountPercentage} OFF
            </p>
          )}
        </div>
        {coupon.check && (
          <div className="space-y-1">
            <div className="flex items-center gap-3">
              <TextInput source="coupon_code" />
              <div className="flex h-10 w-10 items-center justify-center">
                {coupon.loading && (
                  <Icon
                    name="loader"
                    className="animate-spin"
                    size={20}
                  />
                )}
                {couponSuccess && (
                  <Icon
                    name="circle-check"
                    size={26}
                    className="text-green-700"
                  />
                )}
                {couponFailure && (
                  <Icon
                    name="circle-x"
                    size={22}
                    className="text-red-600"
                  />
                )}
              </div>
              <Button
                onClick={handleDebouncedOnChangeCouponInput}
                disabled={!couponCode || coupon.loading}
              >
                Aplicar
              </Button>
            </div>
            {couponSuccess && (
              <p className="text-green-700 text-xs sm:text-sm">
                Cupom aplicado com sucesso!
              </p>
            )}
            {couponFailure && (
              <p className="text-red-600 text-xs sm:text-sm">Cupom inválido!</p>
            )}
          </div>
        )}
      </div>
    </FormProvider>
  );
}
