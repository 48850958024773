import { Decimal } from 'decimal.js';
import { produce } from 'immer';
import { persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';
import type { Dimensions } from '#/common/types/generic-product';
import type { ListProductsByIdsResponse } from '#/server/routers/product/list-products-by-ids';

type ProductProps = {
  minimum: number;
  actual_price_number: number;
  step: number;
  title: string;
  slug: string;
  actual_price: string;
  image: {
    src: string;
    alt: string;
  };
  dimensions: Dimensions;
  categories_ids: string[];
  metric: string;
  quantity: number;
  sku: string;
};

export type ProductOnCart = [product_id: string, value: ProductProps];

type FreightState = {
  // Number is default value, null is when the user has not yet typed and calculated the freight, and 'off_topic' is when for some reason we can't calculate the freight because business rules.
  freightPrice: number | null | 'off_topic';
  shippingPostcode: string | null;
};

type CouponState = {
  loading: boolean;
  isApplied: boolean;
  check: boolean;
  code: string;
  discountPercentage: string | null;
  valid: boolean | null;
};

type State = {
  productsOnCart: ProductOnCart[];
  totalAmountCart: number;
  totalDiscount: number;
  currentStep: number;
  isCartOpen: boolean;
  differentAddresses: boolean;
  freight: FreightState;
  coupon: CouponState;
  inStorePickup: boolean;
};

interface AddProductToCart {
  product_id: string;
  quantity: number;
  actual_price_number: number;
  minimum: number;
  step: number;
}

interface UpdateProductQuantityOnCart {
  product_id: string;
  quantity: number;
}

type Actions = {
  reset: () => void;
  addProductToCart: (props: AddProductToCart) => void;
  updateProductsOnCart: (data: ListProductsByIdsResponse) => void;
  removeProductFromCart: (product_id: string) => void;
  existsProductOnCart: (product_id: string) => boolean;
  updateProductQuantityOnCart: ({
    product_id,
    quantity,
  }: UpdateProductQuantityOnCart) => void;
  calculateTotalAmountCart: () => void;
  clearProductsOnCart: () => void;
  setCurrentStep: (step: number) => void;
  setDiscount: (totalDiscount: number) => void;
  toggleIsCartOpen: () => void;
  toggleDifferentAddresses: () => void;
  setFreight: (freight: FreightState) => void;
  setCoupon: (coupon: Partial<CouponState>) => void;
  setTotalAmountCart: (totalAmountCart: number) => void;
  toggleInStorePickup: () => void;
  nextStep: () => void;
};

const initialCouponState: CouponState = {
  loading: false,
  isApplied: false,
  check: false,
  code: '',
  discountPercentage: null,
  valid: null,
};

const defaultInitState: State = {
  productsOnCart: [],
  totalAmountCart: 0,
  totalDiscount: 0,
  currentStep: 1,
  isCartOpen: false,
  differentAddresses: false,
  freight: {
    freightPrice: null,
    shippingPostcode: null,
  },
  coupon: initialCouponState,
  inStorePickup: false,
};

export const initCartStore = (): State => {
  return defaultInitState;
};

export type CartStore = State & Actions;

export const createCartStore = (initState: State = defaultInitState) =>
  createStore(
    persist<CartStore>(
      (set, get) => ({
        ...initState,
        reset: () => set(defaultInitState),
        setCurrentStep: (step) => {
          set({ currentStep: step });
        },
        nextStep: () => {
          const { currentStep } = get();
          if (currentStep < 5) {
            set({ currentStep: currentStep + 1 });
          }
        },
        toggleIsCartOpen: () => {
          set((state) => ({ isCartOpen: !state.isCartOpen }));
        },
        toggleDifferentAddresses: () => {
          set((state) => ({ differentAddresses: !state.differentAddresses }));
        },
        setFreight: (freight) => {
          set({ freight });
        },
        setCoupon: (coupon) => {
          set((state) => {
            return { coupon: { ...state.coupon, ...coupon } };
          });
        },
        setDiscount: (totalDiscount) => {
          set({ totalDiscount });
        },
        setTotalAmountCart: (totalAmountCart) => {
          set({ totalAmountCart });
        },
        toggleInStorePickup: () => {
          set((state) => ({ inStorePickup: !state.inStorePickup }));
        },
        addProductToCart: ({
          product_id,
          quantity,
          actual_price_number,
          minimum,
          step,
        }: AddProductToCart) => {
          set((prevState) => {
            const mappedProducts = new Map(prevState.productsOnCart);

            mappedProducts.set(product_id, {
              quantity,
              actual_price_number,
              minimum,
              step,
              title: '',
              slug: '',
              actual_price: '',
              sku: '',
              image: {
                src: '',
                alt: '',
              },
              categories_ids: [],
              metric: '',
              dimensions: {
                height: 0,
                length: 0,
                width: 0,
              },
            });

            const productList: Array<ProductOnCart> = Array.from(mappedProducts);

            if (productList.length >= 15) {
              productList.splice(0, productList.length - 15);
            }

            return produce(prevState, (draft) => {
              draft.productsOnCart = productList;
            });
          });
        },
        updateProductsOnCart: (products) => {
          set((prevState) => {
            const mappedProducts = new Map<string, ProductProps>();

            for (const [id, value] of prevState.productsOnCart) {
              const foundProduct = products?.find(({ product_id }) => product_id === id);

              if (foundProduct) {
                mappedProducts.set(id, {
                  ...foundProduct,
                  dimensions: value.dimensions,
                  quantity: value.quantity,
                });
              }
            }

            const productList: Array<ProductOnCart> = Array.from(mappedProducts);

            const cart = Array.from(
              new Map([...prevState.productsOnCart, ...productList]),
            );

            return produce(prevState, (draft) => {
              draft.productsOnCart = cart;
              draft.totalAmountCart = calculateTotalAmountCart(cart);
            });
          });
        },
        updateProductQuantityOnCart: ({ product_id, quantity }) => {
          set((prevState) => {
            const mappedProducts = new Map(prevState.productsOnCart);
            const props = mappedProducts.get(product_id);

            if (props) {
              mappedProducts.set(product_id, { ...props, quantity });
            }

            const productsOnCart = Array.from(mappedProducts);

            return produce(prevState, (draft) => {
              draft.productsOnCart = Array.from(productsOnCart);
              draft.totalAmountCart = calculateTotalAmountCart(productsOnCart);
            });
          });
        },
        removeProductFromCart: (product_id) => {
          set((prevState) =>
            produce(prevState, (draft) => {
              const productsOnCart = new Map(prevState.productsOnCart);
              productsOnCart.delete(product_id);
              draft.productsOnCart = Array.from(productsOnCart);
            }),
          );
        },
        existsProductOnCart: (product_id) =>
          get().productsOnCart.some(([id]) => product_id === id),
        calculateTotalAmountCart: () => {
          set((prevState) =>
            produce(prevState, (draft) => {
              draft.totalAmountCart = calculateTotalAmountCart(prevState.productsOnCart);
            }),
          );
        },
        clearProductsOnCart() {
          set((prevState) =>
            produce(prevState, (draft) => {
              draft.productsOnCart = [];
              draft.totalAmountCart = 0;
            }),
          );
        },
      }),
      { name: 'cart-entremalhas:1.0.1' },
    ),
  );

function calculateTotalAmountCart(productsOnCart: ProductOnCart[]): number {
  let totalAmountCart = new Decimal(0);

  for (const [, { actual_price_number, quantity }] of productsOnCart) {
    const actualPriceNumber = new Decimal(actual_price_number);
    const quantityDecimal = new Decimal(quantity);

    totalAmountCart = quantityDecimal.mul(actualPriceNumber).add(totalAmountCart);
  }

  return totalAmountCart.toDecimalPlaces(2).toNumber();
}
