import type { PropsWithChildren } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { cn } from '#/utils/cn';
import { Icon, type IconProps } from '../Icon';

const StepsIcons: ReadonlyArray<IconProps['name']> = [
  'shopping-cart',
  'user',
  'credit-card',
  'map-pin',
  'gift',
];

export function CartContainer({ children }: PropsWithChildren) {
  const currentStep = useCartStore((state) => state.currentStep);
  const { width } = useWindowSize();

  return (
    <div className="flex h-full flex-col overflow-hidden px-4 pb-5">
      <div className="mx-auto flex w-full max-w-[220px] justify-between py-4 sm:max-w-[280px]">
        {StepsIcons.map((name, index) => (
          <Icon
            key={name}
            name={name}
            className={cn(
              currentStep >= index + 1 ? 'opacity-90' : 'opacity-25',
              'overflow-visible rounded-full border border-black p-1.5 dark:border-white',
              width > 640 && 'p-2',
            )}
            size={width > 640 ? 40 : 34}
            strokeWidth={1.4}
          />
        ))}
      </div>
      {children}
    </div>
  );
}
