import { useEffect } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { useCustomer } from '#/common/hooks/useCustomer';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { Button } from '#/ui/button';
import { Sheet, SheetClose, SheetContent, SheetTitle, SheetTrigger } from '#/ui/sheet';
import { trpc } from '#/utils/trpc';
import { Icon } from '../Icon';
import { IconWithLabel } from '../IconWithLabel';
import { CartAddressSettings } from './CartAddressSettings';
import { CartOrderReview } from './CartOrderReview';
import { CartPaymentMethod } from './CartPaymentMethod';
import { CartPersonalData } from './CartPersonalData';
import { CartProducts } from './CartProducts';

// TODO Implementar skeletons (ver: productsOnCartIsLoading)

const steps: Record<number, JSX.Element> = {
  1: <CartProducts />,
  2: <CartPersonalData />,
  3: <CartPaymentMethod />,
  4: <CartAddressSettings />,
  5: <CartOrderReview />,
};

export function HeaderNavCart() {
  const { mutateAsync, isPending: isPendingApplyCoupon } =
    trpc.couponRouter.applyCoupon.useMutation();
  const {
    currentStep,
    isCartOpen,
    toggleIsCartOpen,
    productsOnCart,
    setDiscount,
    setCoupon,
    couponCode,
    setTotalAmountCart,
    totalAmountCart,
    products,
  } = useCartStore((state) => ({
    currentStep: state.currentStep,
    isCartOpen: state.isCartOpen,
    toggleIsCartOpen: state.toggleIsCartOpen,
    freight: state.freight,
    productsOnCart: state.productsOnCart,
    setDiscount: state.setDiscount,
    setCoupon: state.setCoupon,
    couponCode: state.coupon.code,
    setTotalAmountCart: state.setTotalAmountCart,
    totalAmountCart: state.totalAmountCart,
    products: state.productsOnCart.map(([product_id, value]) => ({
      product_id,
      quantity: value.quantity,
      actual_price_number: value.actual_price_number,
      categories_ids: value.categories_ids,
      sale_item: false,
    })),
  }));
  const email = useCustomer(({ customer }) => customer.email);

  const handleDebouncedOnChangeCouponInput = useDebounceCallback(async () => {
    setDiscount(0);
    setCoupon({
      loading: true,
      isApplied: false,
      valid: null,
      code: couponCode,
    });

    try {
      if (couponCode.trim() !== '') {
        const response = await mutateAsync({
          payload: { code: couponCode, email, products },
        });
        setCoupon({
          valid: response.is_valid_coupon,
          discountPercentage: response.discount_percentage,
          check: true,
          isApplied: true,
          code: couponCode,
        });

        setDiscount(totalAmountCart - response.total_amount);
        setTotalAmountCart(response.total_amount);
      } else {
        setCoupon({ check: true });
      }
    } catch (error) {
      setCoupon({
        valid: false,
        discountPercentage: null,
      });
    }

    setCoupon({ loading: false });
  }, 2000);

  useEffect(() => {
    if (isCartOpen && couponCode) {
      handleDebouncedOnChangeCouponInput();
    }
  }, [productsOnCart]);

  return (
    <Sheet
      open={isCartOpen}
      onOpenChange={toggleIsCartOpen}
    >
      <SheetTrigger>
        <Button
          asChild
          variant="ghost"
          size="lg"
          className="h-fit w-fit p-0"
        >
          <IconWithLabel
            icon="shopping-cart"
            size={30}
            label="CARRINHO"
          />
        </Button>
      </SheetTrigger>
      <SheetContent
        side="right"
        withClose={false}
        className="flex max-w-[400px] flex-col gap-1 border-0 p-0"
      >
        <div className="flex w-full items-center justify-between bg-brand-main px-4 py-2.5">
          <SheetTitle className="cursor-default text-sm text-white/95 uppercase tracking-wide sm:text-base sm:tracking-normal">
            Carrinho
          </SheetTitle>
          <SheetClose className="rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none">
            <Icon
              name="x"
              className="h-4 w-4 text-white/95"
            />
            <span className="sr-only">Close</span>
          </SheetClose>
        </div>
        {steps[currentStep] || null}
      </SheetContent>
    </Sheet>
  );
}
