import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

import type { GetCustomerResponse } from '#/server/routers/customer/get-customer';
import { trpc } from '#/utils/trpc';

const defaultAddress = {
  address: '',
  complement: '',
  city: '',
  postcode: '',
  country: '',
  state: '',
  state_code: '',
};

const defaultCustomer: GetCustomerResponse = {
  email: '',
  full_name: '',
  first_name: '',
  last_name: '',
  phone: '',
  billing: defaultAddress,
  shipping: defaultAddress,
  tax_id: '',
  payment_method: '',
  theme: 'light',
  customer_id: '',
};

interface CustomerContextType {
  customer: GetCustomerResponse;
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

const CustomerContext = createContext<CustomerContextType | null>(null);

export function CustomerProvider({ children }: PropsWithChildren) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [customer, setCustomer] = useState<GetCustomerResponse>(defaultCustomer);

  const { data } = trpc.customerRouter.getCustomer.useQuery();

  useEffect(() => {
    if (data) {
      setCustomer(data);
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [data]);

  return (
    <CustomerContext.Provider value={{ customer, isAuthenticated, setIsAuthenticated }}>
      {children}
    </CustomerContext.Provider>
  );
}

export const useCustomer = <T,>(selector: (state: CustomerContextType) => T): T => {
  const context = useContextSelector(CustomerContext, (ctx) => {
    if (!ctx) {
      throw new Error('useCustomer must be used within a CustomerProvider');
    }
    return selector(ctx);
  });
  return context;
};
