import type { ComponentProps } from 'react';
import { cn } from '#/utils/cn';
import { Icon, type IconProps } from './Icon';

interface IconWithLabelProps extends ComponentProps<'div'> {
  label: string;
  size?: number;
  icon: IconProps['name'];
}

export function IconWithLabel({
  className,
  label,
  size = 20,
  icon,
  ...rest
}: IconWithLabelProps) {
  return (
    <div
      className={cn(className, 'flex flex-col items-center')}
      {...rest}
    >
      <Icon
        name={icon}
        size={size}
        strokeWidth={1.2}
      />
      <p className="font-medium text-[11px]">{label}</p>
    </div>
  );
}
