import { Decimal } from 'decimal.js';
import { useWindowSize } from 'usehooks-ts';
import { useCustomer } from '#/common/hooks/useCustomer';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '#/ui/accordion';
import { ScrollArea } from '#/ui/scroll-area';
import { cn } from '#/utils/cn';
import { trpc } from '#/utils/trpc';
import { Icon } from '../Icon';
import { Title } from '../Title';
import { useToast } from '../ui/use-toast';
import { CartContainer } from './CartContainer';
import { CartFooter } from './CartFooter';

export function CartOrderReview() {
  const { toast } = useToast();
  const { width } = useWindowSize();

  const { billing, shipping, phone, full_name, email, tax_id, payment_method } =
    useCustomer(({ customer }) => ({
      ...customer,
      billing: {
        fullAddress: `${customer.billing.address} ${
          customer.billing.complement ? `- ${customer.billing.complement}` : ''
        }`,
        cityAndState: `${customer.billing.city} - ${customer.billing.state_code}`,
        postcode: customer.billing.postcode,
      },
      shipping: {
        fullAddress: `${customer.shipping.address} ${
          customer.shipping.complement ? `- ${customer.shipping.complement}` : ''
        }`,
        cityAndState: `${customer.shipping.city} - ${customer.shipping.state_code}`,
        postcode: customer.shipping.postcode,
      },
    }));

  const {
    products,
    setCurrentStep,
    clearProductsOnCart,
    total,
    subtotal,
    freight_price,
    payload_products,
    inStorePickup,
    setCoupon,
    totalDiscount,
    setDiscount,
  } = useCartStore((state) => ({
    products: state.productsOnCart.map(([product_id, props]) => ({
      product_id,
      ...props,
    })),
    payload_products: state.productsOnCart.map(([productId, product]) => ({
      product_id: productId,
      name: product.title,
      quantity: product.quantity,
      total: new Decimal(product.actual_price_number)
        .mul(product.quantity)
        .toDecimalPlaces(2)
        .toNumber(),
      sku: product.sku,
      image_src: product.image.src,
      metric: product.metric,
    })),
    clearProductsOnCart: state.clearProductsOnCart,
    setCurrentStep: state.setCurrentStep,
    setCoupon: state.setCoupon,
    setDiscount: state.setDiscount,
    totalDiscount: state.totalDiscount,
    total:
      state.freight.freightPrice !== 'off_topic' && state.freight.freightPrice !== null
        ? state.totalAmountCart + state.freight.freightPrice
        : state.totalAmountCart,
    inStorePickup: state.inStorePickup,
    freight_price: state.freight.freightPrice ?? 0,
    subtotal: state.totalAmountCart,
  }));

  const utils = trpc.useUtils();
  const { mutateAsync, isPending } = trpc.orderRouter.createOrder.useMutation({
    onSuccess: async () => {
      toast({
        variant: 'success',
        title: 'Pedido realizado com sucesso!',
        description: 'Aguarde contato no Whats App',
      });
      clearProductsOnCart();
      setCurrentStep(1);
      setDiscount(0);
      setCoupon({
        code: undefined,
        discountPercentage: null,
        check: false,
        valid: null,
        isApplied: false,
        loading: false,
      });
      await utils.orderRouter.listOrders.invalidate();
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Erro ao realizar pedido. Tente novamente mais tarde!',
      });
    },
  });
  const handleClick = async () => {
    await mutateAsync({
      total: total - totalDiscount,
      freight_price: freight_price === 'off_topic' ? 0 : Number(freight_price),
      subtotal,
      products: payload_products,
      in_store_pickup: inStorePickup,
    });
  };

  return (
    <>
      <CartContainer>
        <ScrollArea className="pr-4">
          <div className="mx-1">
            <div className="mb-3">
              <Title
                uppercase
                className="text-sm sm:text-base"
              >
                Pedido
              </Title>
              <p className="text-gray-400 text-xs">Revise o resumo de seu pedido</p>
            </div>
            <Accordion type="multiple">
              <AccordionItem value="cart">
                <AccordionTrigger className="pt-3 pb-1.5">
                  <div className="flex items-center gap-3">
                    <Icon
                      name="shopping-cart"
                      size={width > 420 ? 20 : 18}
                    />
                    <p
                      className={cn(
                        'font-semibold',
                        width > 420 ? 'text-base' : 'text-sm',
                      )}
                    >
                      Carrinho
                    </p>
                  </div>
                </AccordionTrigger>
                <AccordionContent
                  className={cn(
                    'cursor-default space-y-1.5 pl-[30px]',
                    width > 420 && 'pl-[32px]',
                  )}
                >
                  {products.map(({ product_id, title, quantity, metric }) => (
                    <div
                      key={product_id}
                      className="flex items-start justify-between gap-2"
                    >
                      <p className="text-[13px] sm:text-sm">{title}</p>
                      <p className="mt-[1px] w-16 text-end text-[13px] opacity-70">
                        {quantity} {metric.charAt(0)}
                      </p>
                    </div>
                  ))}
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="personalData">
                <AccordionTrigger className="pt-3 pb-1.5">
                  <div className="flex items-center gap-3">
                    <Icon
                      name="user"
                      size={width > 420 ? 20 : 18}
                    />
                    <p
                      className={cn(
                        'font-semibold',
                        width > 420 ? 'text-base' : 'text-sm',
                      )}
                    >
                      Dados Pessoais
                    </p>
                  </div>
                </AccordionTrigger>
                <AccordionContent
                  className={cn('cursor-default pl-[30px]', width > 420 && 'pl-[32px]')}
                >
                  <div
                    className={cn(
                      'flex flex-col',
                      width > 480 && 'flex-row justify-between',
                    )}
                  >
                    <p className="font-semibold text-[13px]">{full_name}</p>
                    <p
                      className={cn(
                        'text-[11px] opacity-70',
                        width > 480 && 'text-[13px]',
                      )}
                    >
                      {tax_id}
                    </p>
                  </div>
                  <p className="text-[13px] opacity-70">{email}</p>
                  <p className="text-[13px] opacity-70">{phone}</p>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="paymentMethod">
                <AccordionTrigger className="pt-3 pb-1.5">
                  <div className="flex items-center gap-3">
                    <Icon
                      name="credit-card"
                      size={width > 420 ? 20 : 18}
                    />
                    <p
                      className={cn(
                        'font-semibold',
                        width > 420 ? 'text-base' : 'text-sm',
                      )}
                    >
                      Forma de Pagamento
                    </p>
                  </div>
                </AccordionTrigger>
                <AccordionContent
                  className={cn('cursor-default pl-[30px]', width > 420 && 'pl-[32px]')}
                >
                  <p>{payment_method}</p>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="addressSettings">
                <AccordionTrigger className="pt-3 pb-1.5">
                  <div className="flex items-center gap-3">
                    <Icon
                      name="map-pin"
                      size={width > 420 ? 20 : 18}
                    />
                    <p
                      className={cn(
                        'font-semibold',
                        width > 420 ? 'text-base' : 'text-sm',
                      )}
                    >
                      Endereço
                    </p>
                  </div>
                </AccordionTrigger>

                <AccordionContent
                  className={cn(
                    'cursor-default space-y-2 pl-[30px]',
                    width > 420 && 'pl-[32px]',
                  )}
                >
                  <div>
                    <p
                      className={cn(
                        'font-semibold',
                        width > 420 ? 'text-[14px]' : 'text-[13px]',
                      )}
                    >
                      Entrega
                    </p>
                    <div
                      className={cn(
                        'opacity-70',
                        width > 420 ? 'text-[13px]' : '-space-y-0.5 text-[12px]',
                      )}
                    >
                      <p>{shipping.fullAddress}</p>
                      <p>{shipping.cityAndState}</p>
                      <p>{shipping.postcode}</p>
                    </div>
                  </div>
                  <div>
                    <p
                      className={cn(
                        'font-semibold',
                        width > 420 ? 'text-[14px]' : 'text-[13px]',
                      )}
                    >
                      Faturamento
                    </p>
                    <div
                      className={cn(
                        'opacity-70',
                        width > 420 ? 'text-[13px]' : '-space-y-0.5 text-[12px]',
                      )}
                    >
                      <p>{billing.fullAddress}</p>
                      <p>{billing.cityAndState}</p>
                      <p>{billing.postcode}</p>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <div className="mx-4 mt-12 flex flex-col items-center justify-center gap-3">
              <Icon
                name="triangle-alert"
                size={24}
                className="text-orange-600"
              />
              <p
                className={cn(
                  'text-center text-gray-600 dark:text-gray-300',
                  width > 420 ? 'text-sm' : 'text-[12px]',
                )}
              >
                Você <span className="font-semibold">não será cobrado agora</span>. O seu
                pedido será encaminhado para uma vendedora que entrará em contato para
                confirmar a disponibilidade dos itens selecionados e finalizar seu pedido,
                tirando todas suas dúvidas.
              </p>
            </div>
          </div>
        </ScrollArea>
      </CartContainer>
      <CartFooter
        onSubmit={handleClick}
        isLoading={isPending}
      />
    </>
  );
}
