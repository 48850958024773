import { z } from 'zod';
import { PostcodeSchema } from './Postcode.schema';

const MESSAGE_ERROR = 'Campo obrigatório';

export const AddressesSchema = z.object({
  billing_postcode: PostcodeSchema,
  billing_address: z.string().min(1, MESSAGE_ERROR),
  billing_complement: z.string().optional(),
  billing_city: z.string().min(1, MESSAGE_ERROR),
  billing_state_code: z.string().min(2, MESSAGE_ERROR),
  shipping_postcode: PostcodeSchema,
  shipping_address: z.string().min(1, MESSAGE_ERROR),
  shipping_complement: z.string().optional(),
  shipping_city: z.string().min(1, MESSAGE_ERROR),
  shipping_state_code: z.string().min(2, MESSAGE_ERROR),
});

export type Addresses = z.infer<typeof AddressesSchema>;
