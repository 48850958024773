import type { ComponentType, ReactNode } from 'react';

type Provider = ComponentType<any>;

interface ProviderWithProps {
  provider: Provider;
  props?: Record<string, unknown>;
}

interface CombineProvidersProps {
  providers: ProviderWithProps[];
  children: ReactNode;
}

export function CombineProviders({ providers, children }: CombineProvidersProps) {
  const wrappedChildren = providers.reduceRight(
    (acc, { provider: Provider, props }, index) => {
      const key = String(index);

      if (!props) return <Provider key={key}>{acc}</Provider>;

      return (
        <Provider
          key={key}
          {...props}
        >
          {acc}
        </Provider>
      );
    },
    children,
  );

  return <>{wrappedChildren}</>;
}
