import type { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';
import { Icon } from '#/common/components/Icon';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '#/common/components/ui/form';
import { Input } from '#/common/components/ui/input';
import type { BaseInputProps } from './common-models';

type HookFormMaskProps = Parameters<ReturnType<typeof useHookFormMask>>;

type BaseHTMLInputProps = Omit<HookFormMaskProps[2], 'inputType'> &
  Omit<ComponentProps<'input'>, 'type'> & {
    maskType?: HookFormMaskProps[1];
    type?: HTMLInputElement['type'];
  };

interface TextInputProps extends BaseInputProps {
  inputProps?: BaseHTMLInputProps;
  isLoading?: boolean;
}

export const TextInput = (props: TextInputProps) => {
  const { iconProps, label, source, inputProps, isLoading } = props;
  const maskType = inputProps?.maskType || '';
  const formMethods = useFormContext();
  const registerWithMask = useHookFormMask(formMethods.register);

  return (
    <FormField
      control={formMethods.control}
      name={source}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl style={{ margin: 0 }}>
            <div className="relative">
              {isLoading && (
                <Icon
                  name="loader"
                  className="absolute right-3 bottom-2.5 animate-spin"
                  size={20}
                />
              )}
              {iconProps && (
                <Icon
                  {...iconProps}
                  className="absolute top-3 left-3"
                />
              )}
              <Input
                placeholder={inputProps?.placeholder}
                disabled={inputProps?.disabled || isLoading}
                className={iconProps ? 'pl-10' : undefined}
                {...field}
                {...(maskType
                  ? registerWithMask(source, maskType, inputProps as HookFormMaskProps[2])
                  : {})}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
