import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    PORT: z.coerce.number().optional().default(3000),
    VERCEL_URL: z.string().optional(),
    ENTREMALHAS_API_URL: z.string().url(),
    CMS_API_URL: z.string().url(),
    MELHOR_ENVIO_TOKEN: z.string().min(1),
    CONSUMER_SECRET: z.string().min(1),
    CONSUMER_KEY: z.string().min(1),
    JWT_PUBLIC_KEY: z
      .string()
      .transform((value) => Buffer.from(value, 'base64').toString('utf-8')),
  },
  client: {
    NEXT_PUBLIC_UMAMI_ID: z.string().uuid(),
  },
  runtimeEnv: {
    PORT: process.env.PORT,
    VERCEL_URL: process.env.VERCEL_URL,
    ENTREMALHAS_API_URL: process.env.ENTREMALHAS_API_URL,
    NEXT_PUBLIC_UMAMI_ID: process.env.NEXT_PUBLIC_UMAMI_ID,
    JWT_PUBLIC_KEY: process.env.JWT_PUBLIC_KEY,
    CMS_API_URL: process.env.CMS_API_URL,
    MELHOR_ENVIO_TOKEN: process.env.MELHOR_ENVIO_TOKEN,
    CONSUMER_SECRET: process.env.CONSUMER_SECRET,
    CONSUMER_KEY: process.env.CONSUMER_KEY,
  },
});
