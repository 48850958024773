import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '#/common/components/ui/form';
import { RadioGroup, RadioGroupItem } from '#/common/components/ui/radio-group';
import { useCustomer } from '#/common/hooks/useCustomer';
import { useUpdateCustomerMutation } from '#/common/hooks/useUpdateCustomerMutation';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { Icon } from '../Icon';
import { Title } from '../Title';
import { CartContainer } from './CartContainer';
import { CartFooter } from './CartFooter';

const PaymentMethodSchema = z.object({
  payment_method: z.string().default('Pix'),
});

type PaymentMethodForm = z.infer<typeof PaymentMethodSchema>;

export function CartPaymentMethod() {
  const paymentMethod = useCustomer(({ customer }) => customer.payment_method);
  const nextStep = useCartStore((state) => state.nextStep);
  const { mutateAsync, isPending } = useUpdateCustomerMutation();
  const paymentMethodForm = useForm<PaymentMethodForm>({
    resolver: zodResolver(PaymentMethodSchema),
    defaultValues: {
      payment_method: paymentMethod,
    },
  });

  const handleSubmit = async () => {
    const isPaymentMethodFormValid = await paymentMethodForm.trigger();
    if (!isPaymentMethodFormValid) return;
    mutateAsync(paymentMethodForm.getValues()).then(nextStep);
  };

  return (
    <>
      <CartContainer>
        <div className="mx-1 flex w-full flex-col gap-3">
          <div>
            <Title
              uppercase
              className="text-sm sm:text-base"
            >
              Forma de Pagamento
            </Title>
            <p className="text-gray-400 text-xs">Selecione uma opção abaixo</p>
          </div>
          <Form {...paymentMethodForm}>
            <form>
              <FormField
                control={paymentMethodForm.control}
                name="payment_method"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormControl>
                      <RadioGroup
                        defaultValue={field.value}
                        onValueChange={field.onChange}
                        className="flex flex-col space-y-2"
                      >
                        <FormItem className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="Pix" />
                          </FormControl>
                          <FormLabel className="font-normal">Pix</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="TED" />
                          </FormControl>
                          <FormLabel className="font-normal">
                            Transferência Bancária
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="credit" />
                          </FormControl>
                          <FormLabel className="font-normal">
                            Cartão de Crédito{' '}
                            <span className="text-xs opacity-60">(link online)</span>
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
          <div className="mx-4 mt-8 flex flex-col items-center justify-center gap-3">
            <Icon
              name="triangle-alert"
              size={24}
              className="text-orange-600"
            />
            <p className="text-center text-gray-600 text-sm dark:text-gray-300">
              Você <span className="font-semibold">não será cobrado agora</span>. O seu
              pedido será encaminhado para uma vendedora que entrará em contato para
              confirmar a disponibilidade dos itens selecionados e finalizar seu pedido,
              tirando todas suas dúvidas.
            </p>
          </div>
        </div>
      </CartContainer>
      <CartFooter
        onSubmit={handleSubmit}
        isLoading={isPending}
      />
    </>
  );
}
