import { useFormContext } from 'react-hook-form';
import { Icon } from '#/common/components/Icon';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '#/common/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '#/common/components/ui/select';
import type { BaseInputProps } from './common-models';

interface SelectInputProps extends Omit<BaseInputProps, 'iconProps'> {
  choices: Array<{ value: string; name: string }>;
  iconProps?: BaseInputProps['iconProps'];
  inputProps?: {
    placeholder?: string;
  };
}

export const SelectInput = (props: SelectInputProps) => {
  const { iconProps, label, choices, source, inputProps, isDisabled } = props;
  const formMethods = useFormContext();

  return (
    <FormField
      control={formMethods.control}
      name={source}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <Select
            onValueChange={field.onChange}
            value={field.value}
            disabled={isDisabled}
          >
            <FormControl>
              <SelectTrigger>
                <div className="flex items-center gap-3">
                  {iconProps && (
                    <Icon
                      {...iconProps}
                      className="mt-[1px]"
                    />
                  )}
                  <SelectValue placeholder={inputProps?.placeholder} />
                </div>
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {choices.map(({ name, value }) => (
                <SelectItem
                  key={value}
                  value={value}
                  onPointerUp={(event) => event.stopPropagation()}
                  onClick={(event) => event.stopPropagation()}
                >
                  {name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
