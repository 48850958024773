import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';

export function CartSubtotal() {
  const { total, subtotal, freightPrice, hasFreight, totalDiscount } = useCartStore(
    (state) => {
      let freightPrice: string | null;

      switch (true) {
        case state.freight.freightPrice === 0:
          freightPrice = 'Frete Grátis!';
          break;
        case typeof state.freight.freightPrice === 'number':
          freightPrice = `R$ ${formatCurrency(state.freight.freightPrice)}`;
          break;
        default:
          freightPrice = null;
      }

      const totalPriceWithoutCoupon =
        typeof state.freight.freightPrice === 'number'
          ? state.totalAmountCart + state.freight.freightPrice
          : state.totalAmountCart;
      const totalPriceWithCoupon = totalPriceWithoutCoupon - state.totalDiscount;

      return {
        total: `R$ ${formatCurrency(totalPriceWithCoupon)}`,
        subtotal: `R$ ${formatCurrency(state.totalAmountCart)}`,
        freightPrice,
        hasFreight: typeof state.freight.freightPrice === 'number',
        totalDiscount: state.totalDiscount,
      };
    },
  );

  return (
    <div className="flex flex-col gap-0.5">
      <div className="flex cursor-default justify-between text-sm">
        <p className="text-[13px] opacity-50">Subtotal</p>
        <p className="font-semibold opacity-50">{subtotal}</p>
      </div>
      {hasFreight && (
        <div className="flex cursor-default justify-between text-sm">
          <p className="text-[13px] opacity-50">Frete</p>
          <p className="font-semibold opacity-50">{freightPrice}</p>
        </div>
      )}
      {totalDiscount > 0 && (
        <div className="flex cursor-default justify-between text-sm">
          <p className="text-[13px] opacity-50">Desconto</p>
          <p className="font-semibold opacity-50">- R$ {formatCurrency(totalDiscount)}</p>
        </div>
      )}
      <div className="flex cursor-default justify-between font-semibold">
        <p className="opacity-80">Total</p>
        <p className="text-brand-main dark:text-brand-dark">{total}</p>
      </div>
    </div>
  );
}
