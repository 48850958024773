import {
  footerConfigContact,
  footerConfigInstitutional,
  footerConfigOfficeHours,
  footerConfigStore,
} from '#/common/config/footerNav';
import { trpc } from '#/utils/trpc';
import { HighlanderTech } from '../HighlanderTech';
import { Icon } from '../Icon';
import { Separator } from '../ui/separator';
import { FooterOfficeHours } from './FooterOfficeHours';
import { FooterSection } from './FooterSection';

const year = '1987';

export function Footer() {
  const { data: footerConfigCategories = [] } = trpc.pageRouter.footer.useQuery();

  return (
    <footer className="mt-24 w-full bg-brand-main pt-8 pb-4 text-gray-50">
      <div className="container space-y-4 px-4 md:space-y-6">
        <section className="grid grid-cols-2 gap-16 md:flex md:justify-between md:gap-10">
          <FooterSection
            title="Categorias"
            items={footerConfigCategories}
          />

          <FooterSection
            title="Institucional"
            items={footerConfigInstitutional.mainNav}
          />

          <Separator
            orientation="vertical"
            className="hidden h-[160px] bg-white/30 md:block"
          />

          <FooterSection
            className="hidden md:block"
            title="Loja Física"
            items={footerConfigStore.mainNav}
          />

          <FooterSection
            className="hidden lg:block"
            title="Contato"
            items={footerConfigContact.mainNav}
          />
        </section>
        <Separator className="bg-white/30" />
        <section className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-x-16 sm:gap-y-4 sm:space-y-0">
          <FooterSection
            className="md:hidden"
            title="Loja Física"
            items={footerConfigStore.mainNav}
          />
          <FooterSection
            className="md:col-start-1 lg:hidden"
            title="Contato"
            items={footerConfigContact.mainNav}
          />

          <FooterOfficeHours
            items={footerConfigOfficeHours}
            className="sm:col-span-2 md:col-span-1"
          />
        </section>

        <div className="container w-fit space-y-2 pt-4">
          <span className="cursor-default font-semibold text-sm uppercase">
            Redes Sociais
          </span>
          <div className="flex justify-center gap-6">
            <a
              href="https://www.instagram.com/entremalhas/"
              aria-label="Instagram"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="instagram"
                size={30}
              />
            </a>
            <a
              href="https://www.facebook.com/Entremalhas/"
              aria-label="Facebook"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="facebook"
                size={30}
              />
            </a>
          </div>
        </div>
        <div className="mx-auto w-fit cursor-default space-y-0.5 py-10 text-center text-sm lg:text-base">
          <span className="w-fit cursor-default font-semibold uppercase">
            Entremalhas Tecidos
          </span>
          <br />
          <time
            dateTime={year}
            className="text-white/90 uppercase italic"
          >
            Desde {year}
          </time>
        </div>
        <div className="container flex w-fit items-center gap-1.5 text-sm text-white/80">
          <p>desenvolvido por</p>
          <a href={'https://www.highlandertech.com.br/'}>
            <HighlanderTech className="opacity-40 transition-all duration-400 hover:opacity-95" />
          </a>
        </div>
      </div>
    </footer>
  );
}
