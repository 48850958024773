import { Title } from '#/common/components/Title';
import { Checkbox } from '#/common/components/ui/checkbox';
import { Label } from '#/common/components/ui/label';
import { Separator } from '#/common/components/ui/separator';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { CartContainer } from '../CartContainer';
import { DialogAddressesExplanation } from './DialogAddressesExplanation';
import { SameAddressForm } from './SameAddressForm';
import { ShippingAndBillingAddressForm } from './ShippingAndBillingAddressForm';

export function CartAddressSettings() {
  const { toggleDifferentAddresses, differentAddresses, inStorePickup } = useCartStore(
    (state) => ({
      toggleDifferentAddresses: state.toggleDifferentAddresses,
      differentAddresses: state.differentAddresses,
      inStorePickup: state.inStorePickup,
    }),
  );

  return (
    <CartContainer>
      <div className="mx-1 mb-2 flex flex-col gap-4">
        <div>
          <Title
            uppercase
            className="text-sm sm:text-base"
          >
            Endereço
          </Title>
          <p className="text-gray-400 text-xs">
            Informe os endereços de faturamento e entrega
          </p>
        </div>
        <DialogAddressesExplanation />
        {!inStorePickup && (
          <div className="flex gap-2">
            <Checkbox
              id="sameAddress"
              checked={differentAddresses}
              onClick={toggleDifferentAddresses}
              className="mt-0.5"
            />
            <Label
              htmlFor="sameAddress"
              className="text-[13px] leading-snug sm:text-sm"
            >
              Endereços de entrega e faturamento são diferentes
            </Label>
          </div>
        )}
        <Separator />
      </div>
      {differentAddresses && !inStorePickup ? (
        <ShippingAndBillingAddressForm />
      ) : (
        <SameAddressForm />
      )}
    </CartContainer>
  );
}
