import Image, { type ImageProps } from 'next/image';
import { useEffect, useState } from 'react';

interface Props extends ImageProps {
  height: number | `${number}`;
  width: number | `${number}`;
  productName?: string;
}

export function ImageWithFallback({ src, productName = '', ...props }: Props) {
  const fallbackSrc = `https://placehold.co/${props.width}x${props.height}/D6D1CA/AE1857/png?text=${productName}&font=roboto`;
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      src={imgSrc}
      onError={() => setImgSrc(fallbackSrc)}
      {...props}
    />
  );
}
