import { type PropsWithChildren, createContext, useContext, useRef } from 'react';
import { type StoreApi, useStore } from 'zustand';

import {
  type CustomerStore,
  createCustomerStore,
  initCustomerStore,
} from './customer.store';

export const CustomerStoreContext = createContext<StoreApi<CustomerStore> | null>(null);

export const CustomerStoreProvider = ({ children }: PropsWithChildren) => {
  const storeRef = useRef<StoreApi<CustomerStore>>();

  if (!storeRef.current) {
    storeRef.current = createCustomerStore(initCustomerStore());
  }

  return (
    <CustomerStoreContext.Provider value={storeRef.current}>
      {children}
    </CustomerStoreContext.Provider>
  );
};

export const useCustomerStore = <T,>(selector: (store: CustomerStore) => T): T => {
  const customerStoreContext = useContext(CustomerStoreContext);

  if (!customerStoreContext) {
    throw new Error('useCustomerStore must be use within CustomerStoreProvider');
  }

  return useStore(customerStoreContext, selector);
};
