import { useEffect } from 'react';

export async function useAxeCoreReporter() {
  useEffect(() => {
    const axeReporter = async () => {
      if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
        const [axe, React, ReactDom] = await Promise.all([
          require('@axe-core/react'),
          require('react'),
          require('react-dom'),
        ]);

        axe.default(React, ReactDom, 1000);
      }
    };

    (async () => {
      await axeReporter();
    })();
  }, []);
}
