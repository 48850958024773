import { onlyNumbers } from '@brazilian-utils/brazilian-utils';
import type { MainNavType } from '../models/MainNav';

type Day = 'Segunda' | 'Terça' | 'Quarta' | 'Quinta' | 'Sexta' | 'Sábado' | 'Domingo';
type Month =
  | 'Janeiro'
  | 'Fevereiro'
  | 'Março'
  | 'Abril'
  | 'Maio'
  | 'Junho'
  | 'Julho'
  | 'Agosto'
  | 'Setembro'
  | 'Outubro'
  | 'Novembro'
  | 'Dezembro';
type Hour = `${'0' | '1'}${string}:${'0' | '1' | '2' | '3' | '4' | '5'}${string}`;

export type OfficeHourItem = {
  fromDay: Day;
  toDay?: Day;
  months?: Month[];
  fromHour: Hour;
  toHour: Hour;
};

export const footerConfigInstitutional: MainNavType = {
  mainNav: [
    {
      title: 'Quem Somos',
      href: '/quem-somos',
    },
    {
      title: 'Política de Privacidade',
      href: '/politica-de-privacidade',
    },
    {
      title: 'Política de Troca',
      href: '/politica-de-troca',
    },
    /*{
			title: 'Blog',
			href: '/',
		},*/
  ],
};

const ENTREMALHAS_WPP = '(51) 99117-8149';

export const footerConfigContact: MainNavType = {
  mainNav: [
    { title: '(51) 3595-3034', icon: 'headset', href: 'tel:+555135953034' },
    {
      title: ENTREMALHAS_WPP,
      icon: 'phone',
      href: `https://wa.me/${onlyNumbers(ENTREMALHAS_WPP)}?text=Ol%C3%A1%21+Eu+gostaria+de+algumas+informa%C3%A7%C3%B5es.`,
    },
    {
      title: 'entremalhas@entremalhas.com.br',
      icon: 'mail',
      href: 'mailto:entremalhas@entremalhas.com.br',
    },
  ],
};

export const footerConfigStore: MainNavType = {
  mainNav: [
    { title: 'CNPJ 91.980.102/0001-76' },
    { title: 'Rua José do Patrocínio, 858' },
    { title: 'Rio Branco, Novo Hamburgo - RS' },
    { title: '93310-240' },
  ],
};

export const footerConfigOfficeHours: OfficeHourItem[] = [
  { fromDay: 'Segunda', toDay: 'Sexta', fromHour: '08:30', toHour: '18:30' },
  { fromDay: 'Sábado', fromHour: '08:30', toHour: '15:30' },
  {
    fromDay: 'Sábado',
    months: ['Janeiro', 'Fevereiro'],
    fromHour: '08:30',
    toHour: '12:30',
  },
];
