import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '#/common/components/ui/toast';
import { useToast } from '#/common/components/ui/use-toast';
import { cn } from '#/utils/cn';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, ...props }) => (
        <Toast
          key={id}
          {...props}
        >
          <div className="grid gap-1">
            {title && <ToastTitle>{title}</ToastTitle>}
            {description && <ToastDescription>{description}</ToastDescription>}
          </div>
          {action}
          <ToastClose
            className={cn(
              props.variant === 'success' && 'text-green-50 dark:text-green-100',
              props.variant === 'delete' && 'text-red-50 dark:text-red-100',
            )}
          />
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  );
}
