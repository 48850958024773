import type { LucideProps } from 'lucide-react';
import dynamicIconImports from 'lucide-react/dynamicIconImports';
import dynamic from 'next/dynamic';

export interface IconProps extends LucideProps {
  name: keyof typeof dynamicIconImports;
}

export function Icon({ name, size = 18, ...props }: IconProps) {
  const LucideIcon = dynamic(dynamicIconImports[name]);

  return (
    <LucideIcon
      {...props}
      size={size}
    />
  );
}
