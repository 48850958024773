import { z } from 'zod';
import { EmailSchema } from '#/common/schemas/Email.schema';
import { PhoneSchema } from '#/common/schemas/Phone.schema';
import { TaxIdSchema } from '#/common/schemas/TaxId.schema';

export const PersonalDataRegisterSchema = z.object({
  first_name: z.string().min(1, 'Campo obrigatório'),
  last_name: z.string().min(1, 'Campo obrigatório'),
  tax_id: TaxIdSchema,
  email: EmailSchema,
  phone: PhoneSchema,
});

export type PersonalDataRegister = z.infer<typeof PersonalDataRegisterSchema>;
