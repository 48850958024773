import { Decimal } from 'decimal.js';
import { useEffect, useState } from 'react';
import { Button } from '#/ui/button';
import { Input } from '#/ui/input';
import { cn } from '#/utils/cn';
import { Icon } from '../Icon';

export interface InputQuantityProps extends Pick<HTMLDivElement, 'className'> {
  minimum: number;
  step: number;
  value?: number;
  onChangeValue: (value: number) => void;
  size?: 'small' | 'default';
}

const MAXIMUM_VALUE = 100;

const nearestNumber = (value: number, step: number) => {
  const decimal = new Decimal(value);
  const rounded = decimal.toNearest(step);
  return rounded.toDecimalPlaces(2).toNumber();
};

export const InputQuantity = (props: InputQuantityProps) => {
  const { minimum, step, className, onChangeValue, value = 1, size = 'default' } = props;
  const [internalValue, setInternalValue] = useState<number>(value ?? 1);
  const parsedValue = Number(Math.round(internalValue * 100) / 100);

  useEffect(() => {
    setInternalValue(value ?? 1);
  }, [value]);

  useEffect(() => {
    onChangeValue(parsedValue);
  }, [internalValue]);

  return (
    <div className={cn('flex gap-2', className)}>
      <Button
        onClick={() => setInternalValue((state) => state - step)}
        disabled={parsedValue <= minimum}
        className={cn(
          'h-min w-min flex-1 border border-red-600 bg-transparent p-2 text-red-600 transition-colors duration-400 hover:bg-red-600 hover:text-white/90 dark:border-red-600/70 dark:bg-red-600 dark:text-white dark:hover:border-transparent dark:hover:bg-red-600/70',
          size === 'small' && 'p-1',
        )}
      >
        <Icon
          name="minus"
          size={12}
        />
      </Button>
      <Input
        value={parsedValue}
        type="number"
        inputMode="numeric"
        placeholder={minimum.toFixed(2)}
        onChange={(e) => setInternalValue(Number(e.target.value))}
        onBlur={() => {
          if (internalValue <= minimum || internalValue >= MAXIMUM_VALUE) {
            const minimumDiff = Math.abs(internalValue - minimum);
            const maximumDiff = Math.abs(internalValue - MAXIMUM_VALUE);

            if (minimumDiff < maximumDiff) {
              setInternalValue(minimum);
            } else {
              setInternalValue(MAXIMUM_VALUE);
            }
          } else {
            const nearest = nearestNumber(parsedValue, step);
            setInternalValue(nearest);
          }
        }}
        min={minimum}
        max={MAXIMUM_VALUE}
        step={step}
        defaultValue={1}
        className={cn(
          'max-h-[30px] w-full border border-black/20 text-center text-black-2 transition-all [appearance:textfield] hover:border-black focus:border-black focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 dark:border-white/30 dark:focus:border-white/90 dark:hover:border-white/90 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
          size === 'small' && 'max-h-[23px] p-0 text-xs',
        )}
      />
      <Button
        onClick={() => setInternalValue((state) => state + step)}
        disabled={parsedValue >= MAXIMUM_VALUE}
        className={cn(
          'h-min w-min flex-1 border border-green-600 bg-transparent p-2 text-green-600 transition-colors duration-400 hover:bg-green-600 hover:text-white/90 dark:border-green-600/70 dark:bg-green-600 dark:text-white dark:hover:border-transparent dark:hover:bg-green-600/70',
          size === 'small' && 'p-1',
        )}
      >
        <Icon
          name="plus"
          size={12}
        />
      </Button>
    </div>
  );
};
