import { onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Title } from '#/common/components/Title';
import { Form } from '#/common/components/ui/form';
import { ScrollArea } from '#/common/components/ui/scroll-area';
import { useCustomer } from '#/common/hooks/useCustomer';
import { useUpdateCustomerMutation } from '#/common/hooks/useUpdateCustomerMutation';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { TextInput } from '#/core/inputs/text-input';
import { INPUT_MASKS } from '#/core/inputs/util-masks';
import { trpc } from '#/utils/trpc';
import { CartContainer } from './CartContainer';
import { CartFooter } from './CartFooter';
import {
  type PersonalDataRegister,
  PersonalDataRegisterSchema,
} from './schemas/PersonalData.schema';

export function CartPersonalData() {
  const { email, first_name, last_name, phone, tax_id } = useCustomer(({ customer }) => ({
    first_name: customer.first_name,
    last_name: customer.last_name,
    tax_id: customer.tax_id,
    email: customer.email,
    phone: customer.phone,
  }));

  const { freight, nextStep } = useCartStore((state) => ({
    freight: state.freight,
    nextStep: state.nextStep,
  }));

  const utils = trpc.useUtils();
  const { mutateAsync } = useUpdateCustomerMutation();

  const personalDataForm = useForm<PersonalDataRegister>({
    resolver: zodResolver(PersonalDataRegisterSchema),
    defaultValues: { email, first_name, last_name, phone, tax_id },
  });

  const handleSubmit = personalDataForm.handleSubmit(async () => {
    const isPersonalFormValid = await personalDataForm.trigger();
    if (!isPersonalFormValid) return;
    const { phone, tax_id, ...data } = personalDataForm.getValues();

    try {
      if (!freight.shippingPostcode) {
        throw new Error('CEP de entrega não informado.');
      }

      await mutateAsync({
        ...data,
        phone: onlyNumbers(phone),
        tax_id: onlyNumbers(tax_id),
      });
      await utils.customerRouter.getCustomer.invalidate();
      nextStep();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <>
      <CartContainer>
        <ScrollArea className="pr-4">
          <div className="mx-1">
            <div className="mb-3">
              <Title
                uppercase
                className="text-sm sm:text-base"
              >
                Dados Pessoais
              </Title>
              <p className="text-gray-400 text-xs">
                Preencha os dados abaixo para emissão na fatura
              </p>
            </div>
            <Form {...personalDataForm}>
              <form className="flex flex-col gap-2">
                <TextInput
                  label="Nome"
                  source="first_name"
                  iconProps={{ name: 'user' }}
                  inputProps={{ placeholder: 'João' }}
                />
                <TextInput
                  label="Sobrenome"
                  source="last_name"
                  iconProps={{ name: 'user' }}
                  inputProps={{ placeholder: 'Silva' }}
                />
                <TextInput
                  label="CPF/CNPJ"
                  source="tax_id"
                  iconProps={{ name: 'square-user' }}
                  inputProps={{
                    maskType: INPUT_MASKS.DOCUMENT_MASK,
                    placeholder: '000.000.000-00',
                  }}
                />
                <TextInput
                  label="Email"
                  source="email"
                  iconProps={{ name: 'mail' }}
                  inputProps={{ placeholder: 'usuario@gmail.com.br' }}
                />
                <TextInput
                  label="WhatsApp"
                  source="phone"
                  iconProps={{ name: 'phone' }}
                  inputProps={{
                    placeholder: '(00) 00000 0000',
                    maskType: INPUT_MASKS.PHONE_MASK,
                  }}
                />
              </form>
            </Form>
          </div>
        </ScrollArea>
      </CartContainer>
      <CartFooter
        onSubmit={handleSubmit}
        isLoading={personalDataForm.formState.isSubmitting}
      />
    </>
  );
}
