import { getCities, isValidCEP } from '@brazilian-utils/brazilian-utils';
import type { StateCode } from '@brazilian-utils/brazilian-utils/dist/common/states';
import { useEffect, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useDebounceValue } from 'usehooks-ts';
import { trpc } from '#/utils/trpc';
import type { Addresses } from '../schemas/Addresses.schema';

const DEFAULT_STATE: StateCode = 'RS';
const DEFAULT_CITY = 'Porto Alegre';

interface UseAddressesProps {
  form: UseFormReturn<Addresses>;
  type: 'billing' | 'shipping';
}

interface UseAddressesReturn {
  defaultState: StateCode;
  defaultCity: string;
  isLoading: boolean;
  cities: string[];
  error: string | null;
}

export function useAddresses({ form, type }: UseAddressesProps): UseAddressesReturn {
  const watchedPostcode = form.watch(`${type}_postcode`);

  const [debouncedPostcode] = useDebounceValue(watchedPostcode, 500, {
    equalityFn: (prev, next) => prev === next,
  });

  const {
    data: address,
    isLoading,
    error,
  } = trpc.addressRouter.getAddress.useQuery(
    { postcode: debouncedPostcode },
    {
      enabled: isValidCEP(debouncedPostcode),
      retry: false,
    },
  );

  const stateCode = useMemo(() => {
    return (form.watch(`${type}_state_code`) as StateCode) || DEFAULT_STATE;
  }, [form, type]);

  const cities = useMemo(() => {
    return getCities(stateCode);
  }, [stateCode]);

  useEffect(() => {
    if (address) {
      form.reset(
        {
          ...form.getValues(),
          [`${type}_address`]: address.street,
          [`${type}_state_code`]: address.state_code,
          [`${type}_complement`]: '',
          [`${type}_city`]: address.city,
        },
        {
          keepDirty: true,
          keepTouched: true,
          keepErrors: true,
        },
      );
    } else if (debouncedPostcode && !isValidCEP(debouncedPostcode)) {
      form.reset(
        {
          ...form.getValues(),
          [`${type}_address`]: '',
          [`${type}_state_code`]: '',
          [`${type}_complement`]: '',
          [`${type}_city`]: '',
        },
        {
          keepDirty: false,
          keepTouched: false,
          keepErrors: false,
        },
      );
    }
  }, [address, debouncedPostcode, form, type]);

  useEffect(() => {
    const currentCity = form.watch(`${type}_city`);
    if (!currentCity && cities.length > 0) {
      form.setValue(`${type}_city`, cities[0], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [cities, form, type]);

  return {
    defaultState: stateCode,
    defaultCity: cities[0] || DEFAULT_CITY,
    isLoading,
    cities,
    error: error ? 'Erro ao buscar endereço.' : null,
  };
}
