import { getStates } from '@brazilian-utils/brazilian-utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { useForm } from 'react-hook-form';
import { Checkbox } from '#/common/components/ui/checkbox';
import { Label } from '#/common/components/ui/label';
import { Separator } from '#/common/components/ui/separator';
import { useAddresses } from '#/common/hooks/useAddresses';
import { useCustomer } from '#/common/hooks/useCustomer';
import { useUpdateCustomerMutation } from '#/common/hooks/useUpdateCustomerMutation';
import { type Addresses, AddressesSchema } from '#/common/schemas/Addresses.schema';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { SelectInput } from '#/core/inputs/select-input';
import { TextInput } from '#/core/inputs/text-input';
import { Icon } from '../../Icon';
import { Form } from '../../ui/form';
import { CartFooter } from '../CartFooter';

const defaultCity = 'Porto Alegre';

const states = getStates();
export function ShippingAndBillingAddressForm() {
  const { inStorePickup, toggleInStorePickup, freightShippingPostcode } = useCartStore(
    (state) => ({
      inStorePickup: state.inStorePickup,
      toggleInStorePickup: state.toggleInStorePickup,
      freightShippingPostcode: state.freight.shippingPostcode,
    }),
  );

  const { billing, shipping } = useCustomer(({ customer }) => ({
    billing: customer.billing,
    shipping: customer.shipping,
  }));

  const { mutateAsync, isPending } = useUpdateCustomerMutation();

  const formMethods = useForm<Addresses>({
    resolver: zodResolver(AddressesSchema),
    defaultValues: {
      billing_postcode: billing.postcode,
      billing_address: billing.address,
      billing_city: billing.city || defaultCity,
      billing_complement:
        billing.complement === 'Retirar na Loja' ? '' : billing.complement,
      billing_state_code: billing.state_code || 'RS',
      shipping_postcode: freightShippingPostcode || shipping.postcode,
      shipping_address: shipping.address,
      shipping_city: shipping.city || defaultCity,
      shipping_complement:
        shipping.complement === 'Retirar na Loja' ? '' : shipping.complement,
      shipping_state_code: shipping.state_code || 'RS',
    },
    mode: 'onBlur',
  });

  const { isLoading: isShippingLoading, cities: shippingCities } = useAddresses({
    form: formMethods,
    type: 'shipping',
  });

  const { isLoading: isBillingLoading, cities: billingCities } = useAddresses({
    form: formMethods,
    type: 'billing',
  });

  const nextStep = useCartStore((state) => state.nextStep);

  const handleSubmit = async () => {
    const formTrigger = await formMethods.trigger();

    if (!formTrigger) return;

    const values = formMethods.getValues();

    mutateAsync({
      billing: {
        postcode: values.billing_postcode,
        address: values.billing_address,
        city: values.billing_city,
        complement: values.billing_complement,
        state_code: values.billing_state_code,
      },
      shipping: {
        postcode: values.shipping_postcode,
        address: values.shipping_address,
        city: values.shipping_city,
        complement: values.shipping_complement,
        state_code: values.shipping_state_code,
      },
    }).then(() => nextStep());
  };

  const isPendingCodeAddressData = isShippingLoading || isBillingLoading;

  return (
    <>
      <ScrollArea className="flex flex-1 flex-col gap-3 overflow-y-auto pr-4 pb-3">
        <Form {...formMethods}>
          <form>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-3">
                <p className="font-semibold text-sm">Endereço de Entrega</p>
                <Icon
                  name="truck"
                  size={18}
                  className="mt-0.5"
                />
              </div>
              <div className="flex items-center gap-2.5">
                <Checkbox
                  id="inStorePickup"
                  checked={inStorePickup}
                  onClick={toggleInStorePickup}
                />
                <Label
                  htmlFor="inStorePickup"
                  className="font-normal text-[13px] leading-snug sm:text-sm"
                >
                  Desejo retirar na loja Entremalhas
                </Label>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <TextInput
                label="CEP"
                source="shipping_postcode"
                iconProps={{ name: 'signpost' }}
                inputProps={{ maskType: '99999-999' }}
                isDisabled={isPendingCodeAddressData}
              />
              <TextInput
                label="Endereço"
                source="shipping_address"
                iconProps={{ name: 'map-pin' }}
                isDisabled={isPendingCodeAddressData}
              />
              <TextInput
                label="Complemento"
                source="shipping_complement"
                iconProps={{ name: 'hash' }}
                isDisabled={isPendingCodeAddressData}
              />
              <SelectInput
                label="Estado"
                source="shipping_state_code"
                iconProps={{ name: 'flag' }}
                choices={states.map((state) => ({
                  name: state.name,
                  value: state.code,
                }))}
                isDisabled={isPendingCodeAddressData}
              />
              <SelectInput
                label="Cidade"
                source="shipping_city"
                iconProps={{ name: 'building' }}
                choices={shippingCities.map((city) => ({
                  name: city,
                  value: city,
                }))}
                isDisabled={isPendingCodeAddressData}
              />
            </div>
            <Separator />
            <div className="mt-3 flex items-center gap-3">
              <p className="font-semibold text-sm">Endereço de Faturamento</p>
              <Icon
                name="credit-card"
                size={18}
                className="mt-0.5"
              />
            </div>
            <div className="flex flex-col gap-2">
              <TextInput
                label="CEP"
                source="billing_postcode"
                iconProps={{ name: 'signpost' }}
                inputProps={{ maskType: '99999-999' }}
                isDisabled={isPendingCodeAddressData}
              />
              <TextInput
                label="Endereço"
                source="billing_address"
                iconProps={{ name: 'map-pin' }}
                isDisabled={isPendingCodeAddressData}
              />
              <TextInput
                label="Complemento"
                source="billing_complement"
                iconProps={{ name: 'hash' }}
                isDisabled={isPendingCodeAddressData}
              />
              <SelectInput
                label="Estado"
                source="billing_state_code"
                iconProps={{ name: 'flag' }}
                choices={states.map((state) => ({
                  name: state.name,
                  value: state.code,
                }))}
                isDisabled={isPendingCodeAddressData}
              />
              <SelectInput
                label="Cidade"
                source="billing_city"
                iconProps={{ name: 'building' }}
                choices={billingCities.map((city) => ({
                  name: city,
                  value: city,
                }))}
                isDisabled={isPendingCodeAddressData}
              />
            </div>
          </form>
        </Form>
        <CartFooter
          isLoading={isPending}
          onSubmit={handleSubmit}
          className="p-0"
        />
      </ScrollArea>
    </>
  );
}
