import type { PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';

interface Props {
  open: boolean;
  setOpen: (value: SetStateAction<boolean>) => void;
  handleChangeOpenMenu: () => void;
}

const Context = createContext<Props | null>(null);

export function NavigationMenuProvider({ children }: PropsWithChildren) {
  const [open, setOpen] = useState<boolean>(false);
  const handleChangeOpenMenu = () => setOpen((prev) => !prev);

  return (
    <Context.Provider value={{ open, setOpen, handleChangeOpenMenu }}>
      {children}
    </Context.Provider>
  );
}

export function useNavigationMenu() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useNavigationMenu must be used within a NavigationMenuProvider');
  }

  return context;
}
