import { Slot } from '@radix-ui/react-slot';
import type { HtmlHTMLAttributes } from 'react';
import { type VariantProps, tv } from 'tailwind-variants';
import { cn } from '#/utils/cn';

const title = tv({
  base: 'font-semibold dark:text-gray-300',
  variants: {
    size: {
      md: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
    },
    type: {
      default: 'text-black-1',
      subtitle: 'text-base font-normal text-black-3',
    },
  },
});

type TitleVariants = VariantProps<typeof title>;

interface TitleProps extends HtmlHTMLAttributes<HTMLHeadingElement> {
  asChild?: boolean;
  uppercase?: boolean;
  className?: string;
}

type CustomTitleProps = TitleProps & TitleVariants;

export function Title({
  children,
  asChild = false,
  uppercase = false,
  size = 'lg',
  type = 'default',
  className,
  ...rest
}: CustomTitleProps) {
  const Component = asChild ? Slot : 'h1';

  return (
    <Component
      className={cn(title({ size, type }), uppercase && 'uppercase', className)}
      {...rest}
    >
      {children}
    </Component>
  );
}
