import { getCities, getStates, isValidCEP } from '@brazilian-utils/brazilian-utils';
import type { StateCode } from '@brazilian-utils/brazilian-utils/dist/common/states';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCustomer } from '#/common/hooks/useCustomer';
import { useUpdateCustomerMutation } from '#/common/hooks/useUpdateCustomerMutation';
import { type Address, AddressSchema } from '#/common/schemas/Address.schema';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { SelectInput } from '#/core/inputs/select-input';
import { TextInput } from '#/core/inputs/text-input';
import { trpc } from '#/utils/trpc';
import { Icon } from '../../Icon';
import { Checkbox } from '../../ui/checkbox';
import { Form } from '../../ui/form';
import { Label } from '../../ui/label';
import { ScrollArea } from '../../ui/scroll-area';
import { CartFooter } from '../CartFooter';

const states = getStates();

export function SameAddressForm() {
  const billingAddress = useCustomer(({ customer }) => customer.billing);

  const { inStorePickup, toggleInStorePickup, nextStep, cartShippingPostcode } =
    useCartStore((state) => ({
      inStorePickup: state.inStorePickup,
      toggleInStorePickup: state.toggleInStorePickup,
      nextStep: state.nextStep,
      cartShippingPostcode: state.freight.shippingPostcode,
    }));

  const defaultValues: Address = useMemo(
    () => ({
      postcode: cartShippingPostcode || billingAddress.postcode,
      address: billingAddress.address,
      city: billingAddress.city || 'Porto Alegre',
      complement:
        billingAddress.complement === 'Retirar na Loja' ? '' : billingAddress.complement,
      state_code: billingAddress.state_code || 'RS',
    }),
    [cartShippingPostcode, billingAddress],
  );

  const [postcode, setPostCode] = useState<string>(defaultValues.postcode || '');

  const { data: address, isFetching: isFetchingAddress } =
    trpc.addressRouter.getAddress.useQuery({ postcode }, { enabled: !!postcode });

  const { mutateAsync, isPending: isSubmitting } = useUpdateCustomerMutation();

  const formMethods = useForm<Address>({
    resolver: zodResolver(AddressSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const { reset, trigger, watch, getValues } = formMethods;

  useEffect(() => {
    if (address) {
      reset({
        ...getValues(),
        address: address.street,
        state_code: address.state_code,
        city: address.city,
      });
    }
  }, [address, reset, getValues]);

  const handleFillFormWithAddressCode = useCallback(() => {
    const currentPostcode = watch('postcode');
    if (isValidCEP(currentPostcode)) {
      setPostCode(currentPostcode);
    }
  }, [watch]);

  const handleSubmit = useCallback(async () => {
    const isValid = await trigger();

    if (!isValid) return;

    const customerAddress = getValues();

    const storeAddress = {
      postcode: '93310240',
      address: 'R. José do Patrocínio',
      city: 'Novo Hamburgo',
      complement: 'Retirar na Loja',
      state: 'Rio Grande do Sul',
      state_code: 'RS',
      country: 'Brasil',
    } as const;

    await mutateAsync({
      billing: customerAddress,
      shipping: inStorePickup ? storeAddress : customerAddress,
    });

    nextStep();
  }, [trigger, getValues, mutateAsync, inStorePickup, nextStep]);

  const cities = useMemo(() => {
    const stateCode = watch('state_code') as StateCode;
    return Array.from(new Set(getCities(stateCode)));
  }, [watch]);

  return (
    <>
      <ScrollArea className="flex flex-1 flex-col gap-3 overflow-y-auto pr-4 pb-3">
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex gap-3">
            <p className="font-semibold text-sm">
              Endereço de {!inStorePickup && 'Entrega e '}Faturamento
            </p>
            <div className="mt-0.5 flex gap-2">
              {!inStorePickup && (
                <>
                  <Icon
                    name="truck"
                    size={18}
                  />
                  <span className="-translate-y-1 opacity-40">/</span>
                </>
              )}
              <Icon
                name="credit-card"
                size={18}
              />
            </div>
          </div>
          <div className="flex items-center gap-2.5">
            <Checkbox
              id="inStorePickup"
              checked={inStorePickup}
              onClick={toggleInStorePickup}
            />
            <Label
              htmlFor="inStorePickup"
              className="font-normal text-[13px] leading-snug sm:text-sm"
            >
              Desejo retirar na loja Entremalhas
            </Label>
          </div>
          <Form {...formMethods}>
            <form className="flex flex-col gap-2">
              <TextInput
                label="CEP"
                source="postcode"
                iconProps={{ name: 'signpost' }}
                inputProps={{
                  maskType: '99999-999',
                  onChange: handleFillFormWithAddressCode,
                }}
                isLoading={isFetchingAddress}
              />
              <TextInput
                label="Endereço"
                source="address"
                iconProps={{ name: 'map-pin' }}
                isLoading={isFetchingAddress}
              />
              <TextInput
                label="Complemento"
                source="complement"
                iconProps={{ name: 'hash' }}
                isLoading={isFetchingAddress}
              />
              <SelectInput
                label="Estado"
                source="state_code"
                iconProps={{ name: 'flag' }}
                choices={states.map((state) => ({
                  name: state.name,
                  value: state.code,
                }))}
                isDisabled={isFetchingAddress}
              />
              <SelectInput
                label="Cidade"
                source="city"
                iconProps={{ name: 'building' }}
                choices={cities.map((city) => ({ name: city, value: city }))}
                isDisabled={isFetchingAddress}
              />
            </form>
          </Form>
        </div>
        <CartFooter
          onSubmit={handleSubmit}
          isLoading={isSubmitting}
          className="p-0"
        />
      </ScrollArea>
    </>
  );
}
