import { Button } from '#/common/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '#/common/components/ui/dialog';
import { Separator } from '#/common/components/ui/separator';
import { Icon } from '../../Icon';

export function DialogAddressesExplanation() {
  return (
    <div className="flex w-full justify-center">
      <Dialog>
        <DialogTrigger className="text-[13px] underline underline-offset-1">
          Endereços de Faturamento e Entrega?
        </DialogTrigger>
        <DialogContent className="max-w-[340px] rounded-md sm:max-w-[440px]">
          <div className="flex flex-col justify-center gap-8 pt-4 text-sm">
            <div className="space-y-2">
              <Icon
                name="truck"
                size={38}
                strokeWidth={1.4}
                className="mx-auto aspect-square text-primary"
              />
              <p>
                O{' '}
                <span className="font-semibold dark:text-gray-300">
                  endereço de entrega
                </span>{' '}
                é onde os produtos comprados são entregues fisicamente, como em sua casa.
              </p>
            </div>
            <div className="space-y-2">
              <Icon
                name="credit-card"
                size={38}
                strokeWidth={1.4}
                className="mx-auto aspect-square text-primary"
              />
              <p>
                O{' '}
                <span className="font-semibold dark:text-gray-300">
                  endereço de faturamento
                </span>{' '}
                é onde as informações financeiras, como faturas e cobranças, são enviadas.
              </p>
            </div>
            <Separator />
            <p>
              Eles <span className="font-semibold dark:text-gray-300">podem</span> ser
              iguais caso o endereço de cobrança seja igual ao de recebimento dos
              produtos.
            </p>
          </div>
          <DialogClose asChild>
            <Button className="mt-4 uppercase tracking-wide">Entendi</Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </div>
  );
}
