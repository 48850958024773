import Link from 'next/link';
import { useWindowSize } from 'usehooks-ts';
import { trpc } from '#/utils/trpc';
import { HeaderNavCart } from '../Cart';
import { IconWithLabel } from '../IconWithLabel';
import { Logo } from '../Logo';
import { NavigationMenuDesktop, NavigationMenuMobile } from '../NavigationMenu';
import { Button } from '../ui/button';
import { HeaderNavIcons } from './HeaderNavIcons';
import { SearchInput } from './SearchInput';

export function Header() {
  const { width } = useWindowSize();
  //const { data: couponData } = trpc.couponRouter.highlightedCoupon.useQuery();
  const { data: headerNavData = [] } = trpc.pageRouter.header.useQuery();

  /*const params = useMemo(() => {
    if (!couponData) return '';
    return processSearchParams({
      exclude_sale_items: couponData.exclude_sale_items,
      excluded_product_categories: couponData.excluded_product_categories,
      excluded_product_ids: couponData.excluded_product_ids,
      product_categories: couponData.product_categories,
      product_ids: couponData.product_ids,
    });
  }, [couponData]);*/

  return (
    <header className="mb-4 w-full md:mb-0">
      {/*couponData && (
        <div className="bg-black py-2 text-center text-gray-100">
          <Link
            href={`/catalogo?${params}`}
            prefetch={false}
          >
            <p className="text-sm lg:text-base">
              <span className="mr-0.5 font-semibold text-[#F478C4]">
                {couponData.discount} OFF
              </span>{' '}
              {couponData.description}
            </p>
          </Link>
        </div>
      )*/}
      <section className="container space-y-2 px-4 py-3 md:space-y-0 md:py-4 lg:py-6">
        <div className="relative flex items-center justify-between gap-5 py-2 lg:py-0">
          <NavigationMenuMobile items={headerNavData} />
          <Logo />
          {width >= 540 && (
            <Button
              asChild
              variant="ghost"
              size="lg"
              title="Acessar página de favoritos"
            >
              <Link
                href="/minha-conta/favoritos"
                className="absolute right-14 w-9 md:hidden"
                prefetch={false}
              >
                <IconWithLabel
                  icon="heart"
                  size={30}
                  label="FAVORITOS"
                />
              </Link>
            </Button>
          )}
          <Button
            asChild
            variant="ghost"
            size="icon"
            title="Acessar página Minha Conta"
          >
            <Link
              href="/minha-conta"
              className="md:hidden"
              prefetch={false}
            >
              <IconWithLabel
                icon="circle-user"
                size={30}
                label="CONTA"
              />
            </Link>
          </Button>
          <div className="hidden w-full items-center justify-between gap-10 md:flex">
            <SearchInput />
            <HeaderNavIcons />
          </div>
        </div>
        <div className="flex w-full gap-4 md:hidden">
          <SearchInput />
          <div className="w-[48px]">
            <HeaderNavCart />
          </div>
        </div>
        <NavigationMenuDesktop items={headerNavData} />
      </section>
    </header>
  );
}
