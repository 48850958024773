import * as Accordion from '@radix-ui/react-accordion';
import type { ComponentProps } from 'react';
import type { HeaderDataItemProps } from '#/common/types/catalog-filter';
import { cn } from '#/utils/cn';
import { Icon } from '../Icon';
import { IconWithLabel } from '../IconWithLabel';
import { useNavigationMenu } from '../NavigationMenuProvider';
import { Button } from '../ui/button';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';

const CustomClass = {
  Trigger:
    'flex w-full items-center justify-between text-black/70 hover:text-black dark:text-gray-300 dark:hover:text-gray-100 transition-colors [&[data-state=open]>svg]:rotate-180',
  Chevron: 'transition-transform duration-200',
  ContentLeftBorder: 'border-l-[0.7px] dark:border-l-white/10',
  SubRoot:
    'ml-4 first:pt-0 last:pb-0 first:mt-3 last:mb-3 py-3 border-b-[0.7px] last:border-b-0',
};

interface MenuItemProps extends ComponentProps<'div'> {
  items: HeaderDataItemProps[];
}

function MenuItem({ items, className }: MenuItemProps) {
  const { setOpen } = useNavigationMenu();

  return (
    <Accordion.Content className={className}>
      {items.map((item, index) => (
        <Accordion.Root
          key={`${item.title}:${index}`}
          className={CustomClass.SubRoot}
          type="multiple"
        >
          <Accordion.Item value={item.title}>
            <Accordion.Header>
              <Accordion.Trigger className={CustomClass.Trigger}>
                <a
                  onClick={() => setOpen(false)}
                  href={item.url}
                >
                  {item.title}
                </a>
                {item.nestedItem && (
                  <Icon
                    name="chevron-down"
                    className={CustomClass.Chevron}
                  />
                )}
              </Accordion.Trigger>
            </Accordion.Header>
            {item.nestedItem && (
              <MenuItem
                items={item.nestedItem}
                className={CustomClass.ContentLeftBorder}
              />
            )}
          </Accordion.Item>
        </Accordion.Root>
      ))}
    </Accordion.Content>
  );
}

interface HeaderNavProps {
  items: HeaderDataItemProps[];
}

export function NavigationMenuMobile({ items }: HeaderNavProps) {
  const { open, handleChangeOpenMenu, setOpen } = useNavigationMenu();

  return (
    <>
      <Button
        type="button"
        className={cn('w-10 pr-2 lg:hidden')}
        size="icon"
        variant="ghost"
        onClick={handleChangeOpenMenu}
        title="Abrir/fechar menu"
      >
        <IconWithLabel
          icon="menu"
          size={30}
          label="MENU"
        />
      </Button>
      {open && (
        <ScrollArea>
          <div className="scrollbar-none fixed top-0 right-0 bottom-0 left-0 z-50 h-lvh w-lvh overflow-auto bg-background p-4">
            <header className="mb-[14px] flex justify-between">
              <h2 className="font-semibold">MENU</h2>
              <button
                type="button"
                onClick={handleChangeOpenMenu}
                className="text-gray-500 transition-colors hover:text-black dark:text-gray-400 dark:hover:text-gray-200"
                title="Fechar menu"
              >
                <Icon
                  name="x"
                  size={20}
                />
              </button>
            </header>
            <nav>
              <span className="font-semibold text-gray-400 text-sm uppercase dark:text-gray-500">
                Categorias
              </span>
              <Accordion.Root
                className="mt-4 text-sm"
                type="single"
                collapsible
              >
                {items.map((menu, index) => (
                  <Accordion.Item
                    key={`${menu.title}:${index}`}
                    value={menu.title}
                    className="border-b-[0.7px] py-3 last:border-b-0 dark:border-b-white/10"
                  >
                    <Accordion.Header>
                      <Accordion.Trigger className={CustomClass.Trigger}>
                        <a
                          onClick={() => setOpen(false)}
                          href={menu.url}
                        >
                          {menu.title}
                        </a>
                        {menu.nestedItem && (
                          <Icon
                            name="chevron-down"
                            className={CustomClass.Chevron}
                          />
                        )}
                      </Accordion.Trigger>
                    </Accordion.Header>
                    {menu.nestedItem && (
                      <MenuItem
                        items={menu.nestedItem}
                        className={CustomClass.ContentLeftBorder}
                      />
                    )}
                  </Accordion.Item>
                ))}
              </Accordion.Root>
            </nav>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      )}
    </>
  );
}
