import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import { Separator } from '@radix-ui/react-separator';
import Link from 'next/link';
import type { ComponentProps, ReactElement } from 'react';
import { useCustomer } from '#/common/hooks/useCustomer';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { cn } from '#/utils/cn';
import { Icon } from '../Icon';
import { Button } from '../ui/button';
import { CartSubtotal } from './CartSubtotal';

interface Props extends ComponentProps<'footer'> {
  onSubmit: () => void;
  isLoading?: boolean;
}

const Loading = (
  <Icon
    name="loader"
    className="animate-spin"
    size={20}
  />
);

export function CartFooter({ onSubmit, isLoading = false, className }: Props) {
  const {
    currentStep,
    toggleIsCartOpen,
    setCurrentStep,
    products,
    freightPrice,
    shippingPostcode,
    inStorePickup,
  } = useCartStore((state) => ({
    currentStep: state.currentStep,
    shippingPostcode: state.freight.shippingPostcode,
    toggleIsCartOpen: state.toggleIsCartOpen,
    setCurrentStep: state.setCurrentStep,
    inStorePickup: state.inStorePickup,
    freightPrice: state.freight.freightPrice,
    products: state.productsOnCart.map(([productId, product]) => ({
      product_id: productId,
      name: product.title,
      quantity: product.quantity,
      total: `R$ ${formatCurrency(product.actual_price_number * product.quantity)}`,
      sku: product.sku,
      image_src: product.image.src,
    })),
  }));

  const { isAuthenticated } = useCustomer((state) => ({
    isAuthenticated: state.isAuthenticated,
  }));

  const previousStep = () => {
    if (currentStep - 1 <= 0) return;
    return setCurrentStep(currentStep - 1);
  };

  const buttonLabel: Record<number | string, string | ReactElement> = {
    5: isLoading ? Loading : 'Confirmar',
    default: isLoading ? Loading : 'Avançar',
  };

  return (
    <footer className={cn('w-full space-y-4 px-4 pb-5', className)}>
      <Separator />
      {!isAuthenticated && (
        <div className="flex items-center gap-4 rounded-md border border-orange-600 bg-orange-300 px-4 py-2 text-orange-800 text-xs">
          <Icon
            name="triangle-alert"
            size={24}
          />
          <p>
            Para continuar comprando você precisa estar{' '}
            <Link
              href="/login"
              className="font-semibold underline"
              onClick={toggleIsCartOpen}
              prefetch={false}
            >
              conectado a sua conta
            </Link>
          </p>
        </div>
      )}
      <CartSubtotal />
      <div className="flex gap-6">
        {currentStep > 1 && (
          <Button
            variant="outline"
            className="w-full"
            disabled={currentStep === 1 || isLoading}
            onClick={previousStep}
          >
            Voltar
          </Button>
        )}
        <Button
          className="w-full"
          disabled={
            !isAuthenticated ||
            isLoading ||
            products.length === 0 ||
            (freightPrice === null && !inStorePickup) ||
            (!shippingPostcode && !inStorePickup)
          }
          onClick={onSubmit}
        >
          {buttonLabel[currentStep] || buttonLabel.default}
        </Button>
      </div>
    </footer>
  );
}
