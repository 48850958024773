import { persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

interface State {
  recently_viewed_products: Array<string>;
}

type Actions = {
  addRecentlyViewedProduct: (product_id: string) => void;
};

const defaultInitState: State = {
  recently_viewed_products: [],
};

export const initCustomerStore = (): State => {
  return defaultInitState;
};

export type CustomerStore = State & Actions;

export const createCustomerStore = (initState: State = defaultInitState) =>
  createStore(
    persist<CustomerStore>(
      (set) => ({
        ...initState,
        addRecentlyViewedProduct: (product_id) => {
          const maxSize = 30;

          set((state) => {
            const recentlyViewedProducts = new Set(state.recently_viewed_products);
            recentlyViewedProducts.add(product_id);

            const recentlyViewedProductsArray = Array.from(recentlyViewedProducts);

            if (recentlyViewedProducts.size > maxSize) {
              recentlyViewedProductsArray.splice(0, 1);
            }

            return { recently_viewed_products: recentlyViewedProductsArray };
          });
        },
      }),
      { name: 'customer-entremalhas:1.0.1' },
    ),
  );
