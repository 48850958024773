import { Inter } from 'next/font/google';
import type { PropsWithChildren } from 'react';
import { cn } from '#/utils/cn';
import { Footer } from './Footer';
import { Header } from './Header';
import { useNavigationMenu } from './NavigationMenuProvider';

const inter = Inter({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
});

export function Layout({ children }: PropsWithChildren) {
  const { open } = useNavigationMenu();

  return (
    <main className={cn(inter.className, open && 'fixed top-0')}>
      <Header />
      {children}
      <Footer />
    </main>
  );
}
