import { isValidCNPJ, isValidCPF, onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { z } from 'zod';

export const TaxIdSchema = z
  .string()
  .refine(
    (value) => isValidCPF(value) || isValidCNPJ(value),
    'Insira um CPF ou CNPJ válido',
  )
  .transform((value) => onlyNumbers(value));
