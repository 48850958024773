import { useRouter } from 'next/router';
import { type ChangeEvent, type FormEvent, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
//import { z } from 'zod';
import { trpc } from '#/utils/trpc';

export function useSearchInput() {
  const router = useRouter();

  const [inputValue, setInputValue] = useState<string>('');

  /* useEffect(() => {
		 const parsedQuery = z.string().safeParse(router.query.product_name);

		 if (parsedQuery.success) {
			 setInputValue(parsedQuery.data);
		 }
	 }, [router.query.product_name]);*/

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value.trim());
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await router.push(`/catalogo?product_name=${encodeURIComponent(inputValue)}`);
  };

  const [debounced] = useDebounceValue(inputValue, 500);

  const { data: products = [] } = trpc.searchRouter.getSuggest.useQuery(
    {
      title: debounced,
    },
    {
      enabled: !!debounced,
    },
  );

  return {
    handleChange,
    handleSubmit,
    products,
    inputValue,
  };
}
