import { trpc } from '#/utils/trpc';
import { useToast } from '../components/ui/use-toast';

export function useUpdateCustomerMutation() {
  const { toast } = useToast();
  const utils = trpc.useUtils();

  return trpc.customerRouter.updateCustomer.useMutation({
    onSuccess: async () => {
      toast({ variant: 'success', title: 'Dados atualizados com sucesso!' });
      await utils.customerRouter.getCustomer.invalidate();
    },
    onError: () => {
      toast({ variant: 'destructive', title: 'Erro ao atualizar!' });
    },
  });
}
