import type { ComponentProps } from 'react';
import type { NavItem } from '#/common/models/MainNav';
import { cn } from '#/utils/cn';
import { Icon } from '../Icon';

type FooterSectionProps = Pick<ComponentProps<'div'>, 'className'> & {
  title: string;
  items: NavItem[];
};

export const FooterSection = ({ className, title, items }: FooterSectionProps) => {
  return (
    <div className={cn('space-y-1', className)}>
      <span className="cursor-default font-semibold text-sm uppercase">{title}</span>
      <ul
        className={cn(
          'space-y-0.5 md:space-y-1 xl:space-y-1.5',
          title === 'Contato' && 'space-y-1 md:space-y-1.5 xl:space-y-2',
        )}
      >
        {items.map(({ title, href, icon }) => {
          return (
            <li
              key={title}
              className={cn(
                href && 'transition-colors hover:text-white/95',
                'text-sm text-white/80',
              )}
            >
              {href ? (
                <a
                  href={href}
                  className={cn(icon && 'flex gap-1.5')}
                  {...(href.includes('wa.me') && {
                    target: '_blank',
                    rel: 'noreferrer',
                  })}
                >
                  {icon && (
                    <Icon
                      name={icon}
                      size={20}
                    />
                  )}
                  {title}
                </a>
              ) : !icon ? (
                <p>{title}</p>
              ) : (
                <div className="flex gap-1.5">
                  {icon && (
                    <Icon
                      name={icon}
                      size={20}
                    />
                  )}
                  {title}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
