import { type PropsWithChildren, createContext, useContext, useRef } from 'react';
import { type StoreApi, useStore } from 'zustand';
import { type CartStore, createCartStore, initCartStore } from './cart.store';

export const CartStoreContext = createContext<StoreApi<CartStore> | null>(null);

export const CartStoreProvider = ({ children }: PropsWithChildren) => {
  const storeRef = useRef<StoreApi<CartStore>>();

  if (!storeRef.current) {
    storeRef.current = createCartStore(initCartStore());
  }

  return (
    <CartStoreContext.Provider value={storeRef.current}>
      {children}
    </CartStoreContext.Provider>
  );
};

export const useCartStore = <T,>(selector: (store: CartStore) => T): T => {
  const cartStoreContext = useContext(CartStoreContext);

  if (!cartStoreContext) {
    throw new Error('useCartStore must be use within CartStoreProvider');
  }

  return useStore(cartStoreContext, selector);
};
